<template>
  <div>
    <component :is="'overlay'" v-if="showOverlay" :on-click="cancel"></component>
    <div class="modal">
      <div class="header">
        <h5>
          上传图片
          <a-icon @click="cancel" type="close" />
        </h5>
      </div>
      <div class="content">
        <div class="clearfix">
          <ul class="tabs-list clearfix">
            <li
              v-for="(tab, index) in tabList"
              :key="index"
              :class="{ active: tab.active }"
              @click="chooseType(index)"
            >
              {{ tab.name }}
            </li>
          </ul>
        </div>
        <div v-if="activeTab == 'localUpload'" class="local-upload">
          <div @click="addImage">
            <i class="iconfont icon-shangchuan"></i>
            <p>点击添加图片(JPG,JPGE,PNG)</p>
            <input
              type="file"
              accept="image/gif,image/jpeg,image/jpg,image/png"
              name="file"
              @change="onSubmit"
              id="file"
            />
          </div>
          <div v-if="fileResult && fileResult.fileName">
            <label>{{ fileResult.fileName }}</label>
            <i @click="deleteImag" class="icon iconfont icon-delete" style="color: #f5222d"></i>
          </div>
        </div>
        <div v-else class="online-image">
          <label for="">图片URL</label>
          <label class="tail-icon">
            <input type="text" class="form-control" v-model="picSrc" />
            <i class="iconfont icon-module-link"></i>
          </label>
        </div>
      </div>
      <div class="btns">
        <button class="btn btn-sm btn-primary" @click="confirm">
          {{ $t(`确认`) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'
import overlay from './overlay'
import { compress } from '../common/imageCompress'
import { getBlob } from '../common/imageCompress'

export default {
  props: {
    showOverlay: {
      type: Boolean,
      default: true,
    },
    params: {},
    initPicFile: {},
    initPicSrc: {},
  },
  data() {
    return {
      tabList: [
        {
          name: '本地上传',
          view: 'localUpload',
          active: true,
        },
        {
          name: '在线图片',
          view: 'onlineImage',
          active: false,
        },
      ],
      activeTab: 'localUpload',
      file: {},
      fileResult: {},
      picSrc: '',
    }
  },
  mounted() {
    if (this.params && this.params.uploadType == 'localUpload') {
      if (this.initPicSrc) {
        this.picSrc = this.initPicSrc
      }
      this.tabList = [
        {
          name: '本地上传',
          view: 'localUpload',
          active: true,
        },
      ]
    } else if (this.params && this.params.uploadType == 'onLineImage') {
      if (this.initPicFile) {
        this.fileResult = this.initPicFile
      }
      this.tabList = [
        {
          name: '在线图片',
          view: 'onlineImage',
          active: true,
        },
      ]
    } else {
      if (this.initPicSrc) {
        this.picSrc = this.initPicSrc
      }
      if (this.initPicFile) {
        this.fileResult = this.initPicFile
      }
    }
  },
  filters: {},
  computed: {},
  components: { overlay },
  methods: {
    chooseType(index) {
      this.tabList.forEach((item) => {
        item.active = false
      })
      this.tabList[index].active = true
      this.activeTab = this.tabList[index].view
    },
    addImage() {
      document.getElementById('file').click()
    },
    onSubmit() {
      const _self = this
      if (document.getElementById('file').files[0]) {
        this.file = document.getElementById('file').files[0]
        var size = Math.floor(this.file.size / 1024 / 1024)
        if (size > 10) {
          layer.msg('上传文件大小不能大于10M！', { timeout: 2000 })
          this.file = {}
          return false
        }
        var filetypes = ['.gif', '.jpeg', '.png', '.jpg']
        var isnext = false
        var fileend = this.file.name.substring(this.file.name.lastIndexOf('.'))
        for (var i = 0; i < filetypes.length; i++) {
          if (filetypes[i] == fileend.toLowerCase()) {
            isnext = true
            break
          }
        }
        if (!isnext) {
          layer.msg('不接受此文件类型！', { timeout: 2000 })
          this.file = ''
          return false
        }

        if (size > 5) {
          //大于5M进行压缩
          let form = new FormData()
          var reader = new FileReader()
          reader.readAsDataURL(this.file)
          reader.onload = function () {
            var img = new Image()
            img.src = this.result
            var basestr = compress(img)
            var text = window.atob(basestr.split(',')[1])
            var buffer = new Uint8Array(text.length)
            for (var i = 0; i < text.length; i++) {
              buffer[i] = text.charCodeAt(i)
            }
            var blob = getBlob([buffer], _self.file.type)
            form.append('file', blob)
            form.append('batchType', 'SKU_PIC')
            _self.upload(form)
          }
        } else {
          let form = new FormData()
          form.append('file', _self.file)
          form.append('batchType', 'SKU_PIC')
          // var form = new FormData(document.getElementById('file'));
          _self.upload(form)
        }
      } else {
        this.file = {
          name: '',
        }
        return false
      }
    },
    upload(formData) {
      const _self = this
      http({
        instance: _self,
        url: api.uploadBatchOrderFile,
        type: 'post',
        contentType: false,
        processData: false,
        data: formData,
        hasLoading: true,
        success(res) {
          _self.fileResult = res.data.pareseResult.batchFile
          $('#file').val('')
          _self.file = {}
        },
        fail(res) {
          layer.msg(res.subMsg, {
            time: 2000,
          })
          $('#file').val('')
          _self.file = {}
        },
      })
    },
    cancel() {
      this.$emit('cancel', false)
    },
    deleteImag() {
      this.file = {}
      this.fileResult = {}
    },
    confirm() {
      this.$emit('confirm', {
        fileResult: this.fileResult,
        picSrc: this.picSrc,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.modal {
  display: block;
  width: 400px;
  top: 50%;
  left: 0;
  bottom: auto;
  transform: translateY(-135px);
  background-color: $white;
  margin: auto;
  float: none;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  z-index: 1006;
  border-radius: 6px;
  border: 1px solid #ccc;
  /*box-shadow: 0 0 10px 2px #ccc;*/
}
.header {
  border-bottom: 1px solid #ddd;
  /*margin-bottom: 10px;*/
  border-top: 1px solid #ddd;
  background-color: #f7f7f7;
}
label.control-label {
  padding-left: 0px;
  padding-right: 0px;
}
h5 {
  font-weight: 900;
  margin-left: 10px;
  margin-right: 10px;
}
.btn {
  padding-left: 16px;
  padding-right: 16px;
}
label {
  overflow: hidden;
  white-space: nowrap;
}
.btns {
  margin: 0 20px 20px 20px;
  text-align: right;
}
.local-upload div:first-child {
  border: 1px solid #ccc;
  margin: 10px;
  height: 100px;
  text-align: center;
}
.local-upload div .icon-shangchuan {
  font-size: 60px;
  color: #3c8dbc;
}
.local-upload div p {
  margin-top: -20px;
  color: #ccc;
}
.local-upload div {
  cursor: pointer;
}
.local-upload label {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 0;
}
.content {
  min-height: 140px;
}
.online-image {
  padding: 10px 4px;
}
.online-image input {
  width: 280px;
}
.online-image .icon-module-link {
  top: 2px;
}
#file {
  display: none;
}
</style>
